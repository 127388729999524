import React from 'react'
import {
  Grid,
  Label,
  Segment,
} from 'semantic-ui-react'
import {injectIntl, FormattedMessage} from 'react-intl'
import Menu from './NavMenu';
import './footer.scss';

const Footer = ({ mobile }) => (
    <Grid className="footer" stackable padded centered>
      <Grid.Column width={10}>
        <Label><FormattedMessage id="footer.partners" defaultMessage="Partners"/></Label>
        <Segment.Group horizontal>
          <Segment><a href="https://www.gatesfoundation.org/" target="_blank" rel="noopener noreferrer"><img className="logo right" src='/Gates_Foundation.png' alt="Bill & Melinda Gates Foundation Logo"/></a></Segment>
        </Segment.Group>
      </Grid.Column>
      <Grid.Column width={6} className="contact-us">
        <Label><FormattedMessage id="footer.contact" defaultMessage="Contact Us"/></Label>
        <Segment.Group >
          <Segment><a href="https://africafertilizer.org/" target="_blank" rel="noopener noreferrer"><img className="logo right" src='/AFO_logo2.png' alt="Africa Fertilizer Logo"/></a></Segment>
          <div className="afo-email">info@africafertilizer.org</div>
        </Segment.Group>
      </Grid.Column>
      <Grid className="menu" >
        <Grid.Column width={12}>
        <Menu/>
        </Grid.Column>
        <Grid.Column width={4}>
        <div className="developer-logo">
          <div><FormattedMessage id="footer.developedBy" defaultMessage="Developed by"/></div>
          <a href="https://www.developmentgateway.org/" target="_blank" rel="noopener noreferrer">
              <img className="logo right" src='/DG_IREX_Logo_2021.png' alt="Development Gateway INC"/>
          </a>
        </div>
        </Grid.Column>
      </Grid>
    </Grid>

)

export default injectIntl(Footer);
